export default {
    'page.menu-title.default': 'Aveyrin',
    'page.menu-title.auth.login': 'Đăng nhập',
    'page.menu-title.page.notfound': 'Không tìm thấy',
    'page.menu-title.page.access-denied': 'Truy cập bị từ chối',
    'page.menu-title.page.access-error': 'Lỗi',
    'page.menu-title.detail.dashboard': 'Bảng điều khiển',
    'page.menu-title.detail.landing': 'Trang đích',
    'page.menu-title.detail.documentation': 'Tài liệu',
    'page.menu-title.detail.page-empty': 'Trống',
    'page.menu-title.detail.page-crud': 'CRUD',
    'page.menu-title.detail.uikit-formlayout': 'Bố cục biểu mẫu',
    'page.menu-title.detail.uikit-input': 'Đầu vào',
    'page.menu-title.detail.uikit-button': 'Nút',
    'page.menu-title.detail.uikit-table': 'Bảng',
    'page.menu-title.detail.uikit-list': 'Danh sách',
    'page.menu-title.detail.uikit-tree': 'Cây',
    'page.menu-title.detail.uikit-panel': 'Bảng điều khiển',
    'page.menu-title.detail.uikit-overlay': 'Lớp phủ',
    'page.menu-title.detail.uikit-media': 'Phương tiện',
    'page.menu-title.detail.uikit-message': 'Tin nhắn',
    'page.menu-title.detail.uikit-file': 'Tệp tin',
    'page.menu-title.detail.uikit-menu': 'Menu',
    'page.menu-title.detail.uikit-charts': 'Biểu đồ',
    'page.menu-title.detail.uikit-misc': 'Khác',
    'page.menu-title.detail.uikit-timeline': 'Dòng thời gian'
};
